<template>
  <section id="dashboard-ecommerce">
    <validation-observer ref="refDeliveryOrder" #default="{ invalid }">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" :md="openSection ? '9' : '12'">
            <Alert :error="errors" :success="success" />

            <b-row>
              <b-col>
                <h2>
                  Delivery Order Information
                  <span v-if="formData.operation_status">
                    : {{ getOperationStatus(formData.operation_status) }}
                  </span>
                </h2>
                <small v-if="+$route.query.id">
                  last edited: {{ formData.prev_updated_at }}
                </small>
              </b-col>

              <b-col class="text-right">
                <CompanySearch @on-select="(v) => selectCompany(v)" />
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="3">
                <b-form-group
                  label="Delivery Order No."
                  label-for="v-delivery-order-no"
                >
                  <b-form-input
                    v-model="formData.orderno"
                    id="v-delivery-order-no"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="9">
                <b-form-group label="Company" label-for="v-company">
                  <b-form-input v-model="formData.company" id="v-company" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="3">
                <b-form-group label="Email" label-for="v-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <b-form-input
                      :class="errors.length > 0 ? 'is-invalid' : null"
                      :state="errors.length > 0 ? false : null"
                      v-model="formData.email"
                      id="v-email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group label="Sales Person" label-for="v-sales-person">
                  <v-select
                    label="fullname"
                    v-model="formData.employee_id"
                    :options="employeeItems"
                    :reduce="(employeeItems) => employeeItems.id"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group label="Request Date" label-for="v-request-date">
                  <flat-pickr
                    id="v-request-date"
                    v-model="formData.prev_date"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      dateFormat: 'd/m/Y h:i K',
                    }"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" md="3">
                <b-form-group label="Status" label-for="v-status">
                  <b-form-select
                    id="v-status"
                    v-model="formData.status"
                    :options="document_statuses"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <BillingAddress :formData="formData" />
            <DeliveryAddress :formData="formData" />
            <GST :formData="formData" />
            <Details :data-source="dataSource" :formData="formData" />
            <NotesTnC :formData="formData" />
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-button
              type="button"
              class="mr-1"
              variant="outline-danger"
              @click="returnToPage"
            >
              Back
            </b-button>
            <b-button
              :disabled="invalid"
              v-b-modal.tab-information
              type="button"
              class="mr-1"
              variant="success"
              @click="formData.status = 'V'"
            >
              Submit
            </b-button>
            <b-button
              :disabled="invalid"
              v-b-modal.tab-information
              type="button"
              class="mr-1"
              variant="primary"
              @click="formData.status = 'C'"
            >
              Confirm
            </b-button>
            <b-button
              :disabled="invalid"
              v-b-modal.tab-information
              type="button"
              class="mr-1"
              variant="danger"
              @click="formData.status = 'O'"
            >
              Unconfirm
            </b-button>
            <!-- <b-button type="button" class="mr-1" variant="info">
              Print
            </b-button>
            <b-button type="button" variant="secondary">
              Create Quotation
            </b-button> -->

            <Modal modalId="tab-information" @onChange="(v) => onSubmit()" />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </section>
</template>

<script>
import rfqApi from '@api/rfq_headers';

import BillingAddress from '../sections/SectionBilling';
import DeliveryAddress from '../sections/SectionDelivery';
import GST from '../sections/SectionGST';
import Details from '../sections/SectionDetails';
import NotesTnC from '../sections/SectionNotesTnC';

import Alert from '@/views/components/general/Alert';
import Modal from '@/views/components/general/Modal';

import CompanySearch from '@/views/components/general/CompanySearch';

import companiesApi from '@api/companies';
import employeesApi from '@api/employees';

import { required, email } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'DeliveryOrderInformationTab',
  props: {
    section: {
      type: Boolean,
      default: false,
    },
    changeApproval: {
      type: String,
      default: '',
    },
  },
  components: {
    BillingAddress,
    DeliveryAddress,
    GST,
    Details,
    NotesTnC,

    Alert,
    Modal,

    CompanySearch,
  },
  data: () => ({
    required,
    email,
    success: false,
    openSection: false,
    errors: {},
    dataSource: [],
    openSection: false,
    approvalStatus: 'O',
    formData: {
      id: 0,
      orderno: null,
      prev_date: new Date(),
      currency_id: 0,
      gst: 'SGST',
      gst_percent: 7,
      status: 'O',

      billing_name: null,
      billing_company: null,
      billing_email: null,
      billing_addr1: null,
      billing_addr2: null,
      billing_country: 'Singapore',
      billing_postal: null,
      billing_tel: null,
      billing_fax: null,

      delivery_name: null,
      delivery_company: null,
      delivery_email: null,
      delivery_addr1: null,
      delivery_addr2: null,
      delivery_country: 'Singapore',
      delivery_postal: null,
      delivery_tel: null,
      delivery_fax: null,

      notes: null,
      tnc: null,

      version: '1.0',

      employee_id: 0,
      items: [],
    },
    document_statuses: [
      { text: 'Open', value: 'O' },
      { text: 'Confirmed', value: 'C' },
      { text: 'Void', value: 'V' },
      { text: 'Expired', value: 'E' },
    ],
    employeeItems: [],
  }),
  watch: {
    section(v) {
      this.openSection = v;
    },
    changeApproval(v) {
      this.onChangeStatus(v);
    },
  },
  mounted() {
    this.loadEmployees();
    if (+this.$route.query.id) {
      this.loadRfq();
    }
  },
  methods: {
    loadRfq() {
      rfqApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data != null) {
            data.employee_id = data.employee_id
              ? String(data.employee_id)
              : null;

            this.formData = data;

            if (data.details.data && data.details.data.length > 0) {
              data.details.data.gst = data.gst_percent;
              this.dataSource = data.details.data;
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const data = this.formData;

      data.currency_id = +data.currency_id;
      data.employee_id = +data.employee_id;

      if (this.dataSource.length > 0) {
        var items = [];
        this.dataSource.forEach((e) => {
          delete e.id;
          e.qty = +e.qty;

          items.push(e);
        });

        data.details = items;
      }

      if (!data.id) {
        rfqApi
          .add(data)
          .then(({ data }) => {
            window.scrollTo(0, 0);
            this.success = true;
            this.toastConfig();
            this.$router.push(
              `/pages/sales-tools/delivery-order/manage?id=${data.id}`
            );
            this.loadRfq();
          })
          .catch((err) => {
            window.scrollTo(0, 0);
            this.toastConfig('', 'error');
            this.success = false;
            this.errors = err;
          })
          .finally(() => {
            //
          });
      } else {
        rfqApi
          .update(data)
          .then(({ data }) => {
            window.scrollTo(0, 0);
            this.success = true;
            this.toastConfig();
            this.loadRfq();
          })
          .catch((err) => {
            window.scrollTo(0, 0);
            this.toastConfig('', 'error');
            this.success = false;
            this.errors = err;
          })
          .finally(() => {
            //
          });
      }
    },
    returnToPage() {
      this.$router.push(`/pages/sales-tools/delivery-order`);
    },
    onChangeStatus(data) {
      this.formData.operation_status = data;
      this.onSubmit();
    },
    loadEmployees() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      employeesApi
        .list(params)
        .then(({ data }) => {
          this.employeeItems = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    selectCompany(e) {
      if (e) {
        companiesApi
          .get(e.id)
          .then(({ data }) => {
            this.formData.companyid = +e.id;
            this.formData.company = data.company_name;
            this.formData.email = data.email;
            this.formData.employee_id = null;

            if (
              data &&
              data.company_contacts &&
              data.company_contacts.data &&
              data.company_contacts.data.length > 0
            ) {
              this.formData.billing_name = `${data.company_contacts.data[0].firstname} ${data.company_contacts.data[0].lastname}`;
            } else {
              this.formData.billing_name = data.attn;
            }

            this.formData.billing_company = data.company_name;
            this.formData.billing_addr1 = data.addr1;
            this.formData.billing_addr2 = data.addr2;
            this.formData.billing_country = data.country || 'Singapore';
            this.formData.billing_postal = data.postal;
            this.formData.billing_tel = data.tel;
            this.formData.billing_fax = data.fax;
            this.formData.billing_email = data.email;

            this.formData.delivery_company = data.company_name;
            this.formData.delivery_email = data.email;

            this.formData = Object.assign({}, this.formData);
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    getOperationStatus(data) {
      let status = 'New';
      switch (data) {
        case 'A':
          status = 'Approved';
          break;

        case 'S':
          status = 'Submitted';
          break;

        case 'R':
          status = 'Rejected';
          break;
      }

      return status;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
