<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Delivery Order</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="+$route.query.id"
          :variant="openSection ? 'primary' : 'outline-primary'"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle"
          @click="openSection = !openSection"
        >
          <feather-icon icon="MessageCircleIcon" size="16" />
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" :md="openSection ? '9' : '12'">
        <b-card>
          <b-tabs v-model="tabIndex" fill>
            <b-tab title="Information" active>
              <TabInformation :changeApproval="approvalStatus" />
            </b-tab>
            <b-tab title="Documents">
              <TabDocuments ref="documentsTabRefs" />
            </b-tab>
            <b-tab title="Logs">
              <TabLogs ref="logsTabRefs" />
            </b-tab>
            <b-tab title="Comments">
              <TabComments ref="commentsTabRefs" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>

      <b-col cols="12" :md="openSection ? '3' : '12'" v-if="openSection">
        <b-card no-body>
          <b-tabs v-model="tabIndexSection" fill>
            <b-tab active>
              <template #title>
                <small>Comments</small>
              </template>
              <SectionComments ref="commentsRefs" />
            </b-tab>
            <b-tab>
              <template #title>
                <small>Logs</small>
              </template>
              <SectionLogs ref="logsRefs" />
            </b-tab>
            <b-tab>
              <template #title>
                <small>Approval</small>
              </template>
              <SectionApprovals @onChangeStatus="(v) => onChangeStatus(v)" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import TabInformation from "./tabs/TabInformation";
import TabDocuments from "./tabs/TabDocuments";
import TabLogs from "./tabs/TabLogs";
import TabComments from "./tabs/TabComments";

import SectionComments from "./sections/SectionComments";
import SectionLogs from "./sections/SectionLogs";
import SectionApprovals from "./sections/SectionApprovals";

export default {
  name: "DeliveryOrderManage",
  components: {
    TabInformation,
    TabDocuments,
    TabLogs,
    TabComments,

    SectionComments,
    SectionLogs,
    SectionApprovals,
  },
  data: () => ({
    approvalStatus: "",
    openSection: false,
    tabIndex: 0,
    tabIndexSection: 0,
  }),
  watch: {
    tabIndex(v) {
      if (+this.$route.query.id) {
        if (v == 1) {
          this.$refs.documentsTabRefs.loadRfqDocuments();
        }

        if (v == 2) {
          this.$refs.logsTabRefs.loadLogs();
        }

        if (v == 3) {
          this.$refs.commentsTabRefs.loadTimelineHeader();
        }
      }
    },
    tabIndexSection(v) {
      if (+this.$route.query.id) {
        if (v == 0) {
          this.$refs.commentsRefs.loadTimelineHeader();
        }

        if (v == 1) {
          this.$refs.logsRefs.loadLogs();
        }
      }
    },
  },
  mounted() {
    //
  },
  methods: {
    onChangeStatus(data) {
      this.approvalStatus = data;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>