<template>
  <span>
    <b-row>
      <b-col>
        <h2>Delivery Address</h2>
      </b-col>

      <b-col class="text-right">
        <CompanyAddressSearch @onSelect="(v) => selectCompanyAddress(v)" />
      </b-col>
    </b-row>
    <b-row v-if="+formData.id == 0">
      <b-col>
        <b-form-checkbox v-model="isSameAddress">
          Is Delivery Address the same as Billing Address?
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group label="Attn" label-for="v-delivery-attn">
          <b-form-input v-model="formData.delivery_name" id="v-delivery-attn" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Company" label-for="v-delivery-company">
          <b-form-input
            v-model="formData.delivery_company"
            id="v-delivery-company"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Email" label-for="v-delivery-email">
          <validation-provider
            #default="{ errors }"
            name="Delivery Email"
            rules="required|email"
          >
            <b-form-input
              :class="errors.length > 0 ? 'is-invalid' : null"
              :state="errors.length > 0 ? false : null"
              v-model="formData.delivery_email"
              id="v-delivery-email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group
          label="Building/Block/House No. and Street Name"
          label-for="v-delivery-addr1"
        >
          <b-form-input
            v-model="formData.delivery_addr1"
            id="v-delivery-addr1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group
          label="Unit No. and Building Name"
          label-for="v-delivery-addr2"
        >
          <b-form-input
            v-model="formData.delivery_addr2"
            id="v-delivery-addr2"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="Telephone No." label-for="v-delivery-tel">
          <b-form-input v-model="formData.delivery_tel" id="v-delivery-tel" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Fax" label-for="v-delivery-fax">
          <b-form-input v-model="formData.delivery_fax" id="v-delivery-fax" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="Country" label-for="v-delivery-country">
          <v-select
            v-model="formData.delivery_country"
            :options="$store.getters['hbaseGeneral/getAllCountries']"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Postal Code" label-for="v-delivery-postal">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="formData.delivery_postal"
              id="v-delivery-postal"
            />
            <b-input-group-append is-text @click="isShowingMap = !isShowingMap">
              <feather-icon class="pin" icon="MapPinIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <ModalMap :showMap="isShowingMap" @onChangePlace="(v) => getPlace(v)" />
  </span>
</template>

<script>
import vSelect from 'vue-select';
import CompanyAddressSearch from '@/views/components/general/CompanyAddressSearch';
import ModalMap from '@/views/components/general/Map';

export default {
  name: 'DeliveryOrderDeliverySection',
  props: {
    formData: {
      type: Object,
      default: {},
    },
  },
  components: {
    vSelect,

    CompanyAddressSearch,
    ModalMap,
  },
  data: () => ({
    isSameAddress: false,
    isShowingMap: false,
  }),
  watch: {
    isSameAddress(v) {
      const data = this.formData;

      if (v) {
        data.delivery_name = data.billing_name;
        data.delivery_company = data.billing_company;
        data.delivery_email = data.billing_email;
        data.delivery_addr1 = data.billing_addr1;
        data.delivery_addr2 = data.billing_addr2;
        data.delivery_country = data.billing_country;
        data.delivery_postal = data.billing_postal;
        data.delivery_tel = data.billing_tel;
        data.delivery_fax = data.billing_fax;
      } else {
        data.delivery_name = '';
        data.delivery_company = '';
        data.delivery_email = '';
        data.delivery_addr1 = '';
        data.delivery_addr2 = '';
        data.delivery_country = 'Singapore';
        data.delivery_postal = '';
        data.delivery_tel = '';
        data.delivery_fax = '';
      }
    },
  },
  methods: {
    getPlace(place) {
      this.formData.delivery_addr1 = place.name;
      this.formData.delivery_country = place.country;
      this.formData.delivery_postal = place.postal;
      // this.isShowingMap = false;
    },
    selectCompanyAddress(item) {
      if (item) {
        const data = this.formData;

        data.delivery_addr1 = item.addr1;
        data.delivery_addr2 = item.addr2;
        data.delivery_country = item.country || 'Singapore';
        data.delivery_postal = item.postal;
        data.delivery_tel = item.tel;
        data.delivery_fax = item.fax;
      }
    },
  },
};
</script>

<style>
.pin:hover {
  cursor: pointer;
}
</style>
