<template>
  <span>
    <b-row>
      <b-col>
        <h2>Details</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :columns="columns"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @init-new-row="handleEvent('init-new-row', $event)"
          @option-changed="handleEvent('option-changed', $event)"
          @row-removed="handleEvent('row-removed', $event)"
        >
          <DxEditing
            :allow-updating="!isDisabled"
            :allow-deleting="!isDisabled"
            :allow-adding="!isDisabled"
            :use-icons="!isDisabled"
            mode="row"
          />

          <DxColumn type="buttons">
            <DxButton v-if="!isDisabled" icon="image" :onClick="previewItem" />
            <DxButton v-if="!isDisabled" icon="edit" :onClick="editItem" />

            <DxButton v-if="!isDisabled" name="delete" />
          </DxColumn>

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxRowDragging
            :on-reorder="onReorder"
            :allow-reordering="true"
            :show-drag-icons="true"
            drop-feedback-mode="push"
          />
          <!-- <DxSelection mode="multiple" /> -->
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn data-field="product_id" caption="Product">
            <DxLookup
              :data-source="productItems"
              value-expr="id"
              display-expr="name"
            />
          </DxColumn>
          <DxColumn
            :visible="false"
            data-field="serial_number"
            caption="Serial No."
          />

          <DxColumn
            disabled
            data-field="price"
            caption="Price"
            cell-template="cellPriceTemplate"
          />
          <DxColumn data-field="qty" caption="Quantity" />
          <DxColumn
            data-field="gross_amount"
            caption="Gross Amount"
            cell-template="cellGrossAmountTemplate"
          />
          <DxColumn
            data-field="discount_percent"
            caption="Discount Percent"
            cell-template="cellDiscountPercentTemplate"
          />
          <DxColumn
            data-field="discount_amount"
            caption="Discount Amount"
            cell-template="cellDiscountAmountTemplate"
          />
          <DxColumn
            data-field="total"
            caption="Total"
            cell-template="cellTotalTemplate"
          />

          <template #cellPriceTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>
          <template #cellGrossAmountTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>
          <template #cellDiscountPercentTemplate="{ data }">
            {{ `${data.value}%` }}
          </template>
          <template #cellDiscountAmountTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>
          <template #cellTotalTemplate="{ data }">
            {{ moneyFormat(data.value) }}
          </template>

          <DxToolbar>
            <DxItem location="before" name="columnChooserButton" />
            <DxItem location="before" name="searchPanel" />
            <DxItem name="groupPanel" />

            <DxItem v-if="!isDisabled" template="addButtonTemplate" />
          </DxToolbar>

          <DxSummary>
            <DxGroupItem
              column="id"
              summary-type="count"
              display-format="{0} orders"
            />
            <DxGroupItem
              :show-in-group-footer="false"
              :align-by-column="true"
              column="total"
              summary-type="sum"
              value-format="currency"
            />
            <DxGroupItem
              :show-in-group-footer="true"
              column="total"
              summary-type="sum"
              value-format="currency"
              display-format="Total: {0}"
            />
          </DxSummary>

          <DxSortByGroupSummaryInfo summary-item="count" />

          <template #addButtonTemplate>
            <div>
              <b-button
                variant="primary"
                class="text-capitalize"
                @click="addModal = true"
              >
                Add
              </b-button>
            </div>
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-right">
        <div class="">
          Total Amount: <strong>{{ moneyFormat(computedSummaryTotal) }}</strong>
        </div>
        <div class="">
          GST Amount: <strong>{{ moneyFormat(computedSummaryGST) }}</strong>
        </div>
        <div class="">
          Final Amount: <strong>{{ moneyFormat(computedSummaryFinal) }}</strong>
        </div>
      </b-col>
    </b-row>
    <br />

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Product"
      @hide="closeModal()"
    >
      <b-row>
        <b-col class="text-right">
          <ProductSearch @onSelect="(v) => selectProduct(v)" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Product Name" label-for="v-name">
            <b-form-input disabled v-model="formdata_detail.name" id="v-name" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="UOM" label-for="v-uom">
            <b-form-input disabled v-model="formdata_detail.uom" id="v-uom" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="SKU" label-for="v-sku">
            <b-form-input disabled v-model="formdata_detail.sku" id="v-sku" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Quantity" label-for="v-qty">
            <cleave
              id="v-qty"
              name="qty"
              v-model="watchQty"
              class="form-control"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Price" label-for="v-price">
            <cleave
              disabled
              id="v-price"
              name="price"
              v-model="watchPrice"
              class="form-control"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Gross Amount" label-for="v-gross_amount">
            <cleave
              disabled
              id="v-gross_amount"
              name="gross_amount"
              v-model="watchGrossAmount"
              class="form-control"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="4">
          <b-form-group label="Discount Percent" label-for="v-discount_percent">
            <cleave
              id="v-discount_percent"
              name="discount_percent"
              v-model="watchDiscountPercent"
              class="form-control"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Discount Amount" label-for="v-discount_amount">
            <cleave
              id="v-discount_amount"
              name="discount_amount"
              v-model="watchDiscountAmount"
              class="form-control"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Total" label-for="v-total">
            <cleave
              disabled
              id="v-total"
              name="total"
              v-model="watchTotal"
              class="form-control"
              :raw="false"
              :options="options.number"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import productsApi from '@api/products';
import { moneyFormat as mf } from '@/global-functions';
import { queryParameters } from '@/schema';

import Cleave from 'vue-cleave-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us';

export default {
  name: 'DeliveryOrderDetailsSection',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Cleave,
  },
  watch: {
    dataSource(v) {
      //
    },
    watchQty(v) {
      const fd = this.formdata_detail;
      if (v) {
        const value = typeof v == 'string' ? v.replace(/,/g, '') : 0;
        if (value) {
          fd.qty = +value;
          fd.gross_amount = fd.qty * fd.price;
          this.watchGrossAmount = fd.qty * fd.price;

          const dp = +fd.discount_percent;
          const da = +fd.discount_amount;

          if (!+dp) {
            fd.total = fd.gross_amount;
            this.watchTotal = fd.total;
          }

          this.watchDiscountPercent = 0;
          this.watchDiscountAmount = 0;
        }
      }
    },
    watchDiscountPercent(v) {
      const fd = this.formdata_detail;
      if (v) {
        const value = typeof v == 'string' ? v.replace(/,/g, '') : 0;
        if (value) {
          fd.discount_percent = +value;

          const discount = fd.gross_amount * (fd.discount_percent / 100);
          const total = fd.gross_amount - discount;
          fd.total = +String(total).replace(/,/g, '');
          this.watchTotal = fd.total;
        }
      }
    },
    watchDiscountAmount(v) {
      const fd = this.formdata_detail;
      if (v) {
        const value = typeof v == 'string' ? v.replace(/,/g, '') : 0;
        if (value) {
          fd.discount_amount = +value;

          const total = fd.gross_amount - +value;
          fd.total = +String(total).replace(/,/g, '');
          this.watchTotal = fd.total;
        }
      }
    },
  },
  computed: {
    computedSummaryTotal: {
      get() {
        const value = this.dataSource.reduce((a, b) => a + b.gross_amount, 0);
        return value;
      },
    },
    computedSummaryGST: {
      get() {
        const value = this.dataSource.reduce((a, b) => a + b.gross_amount, 0);
        const gst = this.dataSource.gst || this.formData.gst_percent;
        const wgst = value * (gst / 100);
        return wgst || 0;
      },
    },
    computedSummaryFinal: {
      get() {
        const value = this.dataSource.reduce((a, b) => a + b.gross_amount, 0);
        const gst = this.dataSource.gst || this.formData.gst_percent;
        const wgst = value * (gst / 100);
        return value + wgst || 0;
      },
    },
  },
  data: () => ({
    dataIndex: -1,
    dataItems: [],
    addModal: false,
    formdata_detail: {
      id: 0,
      product_id: 0,
      uom: null,
      serial_number: null,
      qty: 1,
      price: 0,
      total: 0,
      discount_percent: 0,
      discount_amount: 0,
      gross_amount: 0,
      status: 'O',
    },

    options: {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        rawValueTrimPrefix: true,
        onValueChanged: (e) => {
          if (+e.target.rawValue) {
            //
          }
        },
      },
    },

    columns: [
      { dataField: 'product_id', caption: 'Product' },
      { dataField: 'serial_number', caption: 'Serial No.' },
      { dataField: 'price', caption: 'Price' },
      { dataField: 'qty', caption: 'Quantity' },
      { dataField: 'total', caption: 'Total' },
    ],

    productItems: [],
    watchQty: '1',
    watchPrice: 0,
    watchGrossAmount: 0,
    watchDiscountPercent: 0,
    watchDiscountAmount: 0,
    watchTotal: 0,
  }),
  mounted() {
    this.loadProducts();
  },
  methods: {
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        product_id: 0,
        uom: null,
        serial_number: null,
        qty: 1,
        price: 0,
        total: 0,
        discount_percent: 0,
        discount_amount: 0,
        gross_amount: 0,
        status: 'O',
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.formdata_detail = Object.assign({}, defaultForm);

        this.watchQty = 1;
        this.watchPrice = 0;
        this.watchGrossAmount = 0;
        this.watchDiscountPercent = 0;
        this.watchDiscountAmount = 0;
        this.watchTotal = 0;
      });
    },
    loadProducts() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      productsApi
        .list(params)
        .then(({ data }) => {
          var items = [];
          data.forEach((e) => {
            const item = {
              id: +e.id,
              name: e.name,
            };

            items.push(item);
          });

          this.productItems = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(action, event) {},
    onReorder(e) {
      console.log(e);
      const visibleRows = e.component.getVisibleRows();
      const toIndex = this.dataSource.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = this.dataSource.indexOf(e.itemData);
      const newDataSource = [...this.dataSource];

      newDataSource.splice(fromIndex, 1);
      newDataSource.splice(toIndex, 0, e.itemData);

      this.dataSource = newDataSource;
    },
    editItem(event) {
      if (event) {
        this.addModal = true;
        this.$nextTick(() => {
          const data = event.row.data;
          const product = this.productItems.find((x) => {
            return x.id == data.product_id;
          });

          data.name = product.name;
          this.dataIndex = event.row.dataIndex;
          this.formdata_detail = Object.assign({}, data);

          this.watchPrice = data.price;
          this.watchQty = data.qty;
          this.watchDiscountPercent = data.discount_percent;
          this.watchDiscountAmount = data.discount_amount;
          this.watchGrossAmount = data.gross_amount;
          this.watchTotal = data.total;
        });
      }
    },
    previewItem(event) {
      //
    },
    moneyFormat(data) {
      return mf(data);
    },
    selectProduct(data) {
      if (data) {
        this.loadProducts();
        const ds = this.dataSource[this.dataIndex];
        const dI = this.dataIndex > -1;
        const item = {
          id: dI ? ds.id : this.dataSource.length + 1,
          product_id: +data.id,
          serial_number: null,
          name: data.name,
          qty: dI ? String(ds.qty) : '1',
          price: +data.price,
          discount_percent: dI ? ds.discount_percent : 0,
          discount_amount: dI ? ds.discount_amount : 0,
          gross_amount: dI ? ds.gross_amount : data.price * 1,
          total: dI ? ds.total : data.price * 1,
          status: 'O',
        };

        this.watchPrice = data.price;
        this.watchQty = item.qty;
        this.watchDiscountPercent = item.discount_percent;
        this.watchDiscountAmount = item.discount_amount;
        this.watchGrossAmount = item.gross_amount;
        this.watchTotal = item.total;

        this.formdata_detail = Object.assign({}, item);
      }
    },
    onSubmit() {
      if (this.dataIndex > -1) {
        this.dataSource.splice(this.dataIndex, 1);
        this.dataSource.splice(this.dataIndex, 0, this.formdata_detail);
      } else {
        this.dataSource.push(this.formdata_detail);
      }

      this.closeModal();
    },
  },
};
</script>

<style></style>
