<template>
  <span>
    <b-row>
      <b-col>
        <h2>GST Information</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group label="GST" label-for="v-gst">
          <b-form-input v-model="formData.gst" id="v-gst" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="GST Percent" label-for="v-gst-percent">
          <b-form-input
            v-model.number="formData.gst_percent"
            id="v-gst-percent"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Currency" label-for="v-currency">
          <b-form-select
            v-model="formData.currency_id"
            :options="currencyItems"
            value-field="id"
            text-field="currency_id"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </span>
</template>

<script>
import vSelect from 'vue-select';
import currenciesApi from '@api/currencies';
import { queryParameters } from '@/schema';

export default {
  name: 'DeliveryOrderGSTSection',
  components: {
    vSelect,
  },
  props: {
    formData: {
      type: Object,
      default: {},
    },
  },
  data: () => ({
    currencyItems: [],
  }),
  mounted() {
    this.loadCurrencies();
  },
  methods: {
    loadCurrencies() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      currenciesApi
        .list(params)
        .then(({ data }) => {
          this.currencyItems = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
